
import AppLocale from "../../languageProvider";
import config, { getCurrentLanguage } from "../../settings/languageConfig";

const currentAppLocale =
    AppLocale[getCurrentLanguage(config.defaultLanguage || "french").locale];
export default function dateFormat(date_) {
    
    return date_ ? new Date(date_).toLocaleDateString(currentAppLocale.locale) : null;
}
 