import React, {useState, useEffect} from "react";
import {AdaButton} from "@adaming/ada-react-component";
import {Col, Row, Table} from "react-bootstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import AssignmentAddLineModal from "./assignment.addLineModal";
import * as service from "../../../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName} from "../../../../service/host";
import {element} from "prop-types";
import dateformat from "../../../commun/date.format";

export default function AssignmentSellPriceTable({
                                                     register,
                                                     setValue,
                                                     errors,
                                                     assignment, handlerSave,
                                                     edit, details, related="assignment"
                                                 }
) {
    const [modalAddLineShow, setModalAddLineShow] = React.useState(false);
    const [lines, setLines] = useState([]);

    useEffect(() => {
        register({name: "rates"});
        if (assignment.id !== undefined) {
            getAllLines();
        }
    }, [assignment]);

    const errorMessage = error => {
    }

    const getAllLines = () => {
        service.get(
            getHostByAppName(ASSIGNMENT_NAME),
            `rates/findBy/${related}/${assignment.id}`,
            setLines,
            errorMessage
        );
    }

    const handlerSaveAddLine = data => {
        console.log("data line : ", data);
        let line = {
            beginAt: data.beginAtPrice !== undefined ? data.beginAtPrice : null,
            endAt: data.endAtPrice !== undefined ? data.endAtPrice : null,
            rate: Math.round(data.rate),
            enable: true
        };
        let lineToSave = lines.concat(line);
        if (assignment.id !== undefined) {
            handlerSaveLine({...line, relatedTo : assignment.id});
        } else {
            setLines(lineToSave);
            setValue("rates", lineToSave);
        }
    };

    const handlerSaveLine = line => {
        service.post(getHostByAppName(ASSIGNMENT_NAME), `rates`, getAllLines, ((error)=>{console.log(error)}), line)
    }

    const handlerDeleteLine = line => {
        service.deletes(getHostByAppName(ASSIGNMENT_NAME), `rates`, getAllLines, ((error)=>{console.log(error)}), [line.id])
    }

    const deleteElementFromList = (key, list) => {
        console.log("key", key);
        let arr = [];
        let lineToDelete = {}
        list.forEach((value, keyLoop) => {
            if (key !== keyLoop) {
                arr.push(value);
            }else{
                lineToDelete = value;
            }
        });
        if (assignment.id !== undefined) {
            handlerDeleteLine(lineToDelete);
        } else {
            setValue("rates", arr);
        }
        return arr;
    };

    return (
        <div className="pl-2">
            <Row>
                <Col className="col-sm-7 ">
                    <IntlMessages id="assignment.price.sell"/>
                    {!details && <AdaButton
                        className="c-add-btn ml-sm-2 mb-2"
                        onClick={() => setModalAddLineShow(true)}
                    >
                        <i className="fas fa-plus"/>
                    </AdaButton>
                    }
                </Col>

            </Row>
            <Row>
                <Col>
                    <Table hover responsive bordered striped>
                        <thead align="left">
                        <tr>
                            {edit && <th width="5%"/>}
                            <th width="20%">
                                <IntlMessages id="assignment.date.start"/>
                            </th>
                            <th width="20%">
                                <IntlMessages id="assignment.date.end"/>
                            </th>

                            <th width="20%">
                                <IntlMessages id="assignment.total.amount.ht"/>
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {lines !== undefined && lines !== null && (lines.map((line, key) => (
                            <tr key={key}>
                                {edit && (
                                    <td>
                                        <AdaButton
                                            className="c-danger"
                                            onClick={() =>
                                                setLines(deleteElementFromList(key, lines))
                                            }
                                        >
                                            <i className="fas fa-trash"/>
                                        </AdaButton>
                                    </td>
                                )} 
                                <td>{dateformat(line.beginAt)}</td>

                                <td>{dateformat(line.endAt)}</td>
                                <td>{line.rate}</td>
                            </tr>
                        )))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <AssignmentAddLineModal
                show={modalAddLineShow}
                onHide={() => setModalAddLineShow(false)}
                processAction={handlerSaveAddLine}

            />
        </div>
    );
}
