import React, {useEffect, useState} from "react";
import {Col, FormGroup, Row} from "reactstrap";
import IntlMessages from "../../../helpers/intlMessages";
import {useHistory} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import * as service from "../../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName} from "../../../service/host";
import OrderContract from "../create/order.contract";
import OrderAssignment from "../create/order.assignment";
import OrderFacturation from "../create/order.facturation";
import Details from "../../assignment/details/details";
import Steps from "../../assignment/components/assignment.consultation/steps";
import AssignmentAction from "../../assignment/components/assignment.consultation/assignment.actions";
import Documents from "../../assignment/components/documents";
import AssignmentDisplayCollab from "../../assignment/components/assignment.consultation/assignment.display.collab";
import PageviewsChartWrapper from "../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";
import AssignmentContractTypeAndDateOrder
    from "../../assignment/components/assignment.consultation/assignment.display.contractTypeOrder";
import AssignmentSellPriceTable from "../../assignment/components/assignment.order/assignment.sellPriceTable";
import AssignmentDiscountPriceTable from "../../assignment/components/assignment.order/assignment.discountPriceTable";
import AssignmentDisplayClient from "../../assignment/components/assignment.consultation/assignment.display.client";
import OrderFacturationDetails from "../create/order.facturation.details";

const context = "orders";
export default function OrderEditForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        control,
        unregister
    } = useForm();

    const [edit, setEdit] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [order, setOrder] = useState({});
    const [selectedObjects, setSelectedObjects] = useState({})
    const [errorMessage, setErrorMessage] = useState("")

    let history = useHistory();

    const cancelCreateOrder = data => {
        history.push("/orders");
    };

    const orderId = initAssignmentId();

    function initAssignmentId() {
        const {
            match: {params}
        } = props;
        return params.orderId;
    }

    useEffect(
        () => {
            if (orderId !== undefined) {
                service.getById(
                    getHostByAppName(ASSIGNMENT_NAME),
                    context,
                    callBackOrder,
                    setErrorMessage,
                    orderId
                );
            }
        },
        [orderId]
    );

    const callBackOrder = data => {
        setOrder(data)
        setSelectedObjects({assignment: data.assignment});
    }

    const onSubmitOrder = data => {
        if (isValid) {
            let mappedData = mapData(data);
            console.log("Save assignment : ", mappedData);
            console.log(JSON.stringify(mappedData));
            service.put(getHostByAppName(ASSIGNMENT_NAME), `${context}/${order.id}`, callbackRes, callbackError, {...order, ...mappedData});
        } else {
            console.log("is not valid");
        }
    };

    const callbackRes = (res) => {
        console.log(res.data);
        setEdit(false);
    }

    const callbackError = (error) => {
        console.log("error to print", error.message);
        setErrorMessage(error.response.data.message)
    }

    const mapData = data => {
        console.log("order data", data)
        let dataTransforme = {
            ...data,
            beginAt: data.beginAt !== undefined ? data.beginAt.replace("T", " ") : null,
            endAt: data.endAt !== undefined ? data.endAt.replace("T", " ") : null,
            billingAddOns: data.billingAddOns != undefined && data.billingAddOns.length > 0 ? data.billingAddOns : []
        };

        return dataTransforme;
    };

    const dispatch = (obj) => {
        console.log("selectItems values : ", selectedObjects)
        setSelectedObjects({...selectedObjects, ...obj});
    }

    const cancelEdit = () => {
        setEdit(false);
        service.getById(
            getHostByAppName(ASSIGNMENT_NAME),
            context,
            setOrder,
            setErrorMessage,
            orderId
        );
    };

    const handlerSave = data => {
        console.log("in save data patch", {...data});
        service.put(
            getHostByAppName(ASSIGNMENT_NAME),
            `${context}/${order.id}`,
            callbackRes,
            callbackError,
            {...data}
        );
    };

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ">
                    <div className="roe-card-style">
                        <Row className="pt-2">
                            <Col className="col-sm-4 pl-5">
                                <h4 className="mb-0 fs-22 bold-text" style={{color: "#FF0B0E"}}><IntlMessages
                                    id="order.contract.pres"/></h4>
                            </Col>
                            <Col className="col-sm-7 pl-5">
                                <Row>
                                    <Col>
                                        <Steps
                                            steps={order.status !== undefined && order.status !== null ? order.status : []}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pt-2 mb-2">
                            <Col className="col-sm-2"/>
                            <Col className="pl-5 align-self-end col-sm-10">
                                {!edit && (
                                    <AssignmentAction
                                        context={context}
                                        setEdit={setEdit} reload={cancelEdit}
                                        setErrorMessage={setErrorMessage}
                                        assignment={order}
                                        setAssignment={setOrder}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>


            <br/>
            <form onSubmit={handleSubmit(onSubmitOrder)}>
                <Row>
                    <Col className="col-sm-6">
                        <div className="display-5 c-text-danger">{errorMessage}</div>
                    </Col>
                </Row>
                <div className="row ma-0">
                    <div className="col-sm-12 ">
                        <Row className="ptb-10">
                            <Col className="col-sm-6">
                                <AssignmentDisplayCollab assignment={order}/>
                            </Col>

                            <Col className="col-sm-6">
                                <PageviewsChartWrapper>
                                    <div className="page-view-chart-widget-card pl-4 pt-3 pb-3">
                                        <Row className="pt-3">
                                            <Col>
                                                <div>
                                                    <span className="hash"># </span>
                                                    <IntlMessages id="employee.mission.Type"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-3">
                                            <Col>
                                                <AssignmentContractTypeAndDateOrder
                                                    assignment={order}
                                                    register={register}
                                                    edit={edit}
                                                    errors={errors}
                                                    setValue={setValue}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="pt-3">
                                            <Col>
                                                <div>
                                                    <span className="hash"># </span>
                                                    <IntlMessages id="common.mission.description"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-3 mr-3">
                                            <Col>
                                                <div className="pl-3">
                                                    <AdaInputText
                                                        name="designation"
                                                        errors={errors}
                                                        innerRef={register}
                                                        defaultValue={
                                                            order !== undefined
                                                                ? order.designation
                                                                : ""
                                                        }
                                                        disabled={!edit}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="pt-3">
                                            <Col>
                                                <div>
                                                    <span className="hash"># </span>
                                                    <IntlMessages id="assignment.mission.task"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-3 mr-3">
                                            <Col>
                                                <div className="pl-3">
                                                    <AdaInputText
                                                        name="task"
                                                        errors={errors}
                                                        innerRef={register}
                                                        defaultValue={
                                                            order !== undefined
                                                                ? order.task
                                                                : ""
                                                        }
                                                        disabled={!edit}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </PageviewsChartWrapper>

                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="row ma-0">
                    <div className="col-sm-12 ">
                        <div className="roe-card-style mt-3">
                            <div className="roe-card-header">
                                <span className="hash"># </span><IntlMessages id="info.comp.fact"/>
                            </div>

                            <OrderFacturationDetails control={control} dispatch={dispatch} edit={edit} order={order}
                                              setValue={setValue} errors={errors} register={register}/>

                        </div>
                    </div>
                </div>

                <div className="row ma-0">
                    <div className="col-sm-12 ">
                        <div className="roe-card-style mt-3">
                            <div className="roe-card-header">
                                <span className="hash"># </span><IntlMessages id="info.comp.fact"/>
                            </div>

                            <OrderFacturation control={control} dispatch={dispatch} edit={edit} order={order}
                                              setValue={setValue} errors={errors} register={register}/>

                        </div>
                    </div>
                </div>


                <br/>

                <Row>
                    <Col className="col-sm-9"/>
                    <Col>
                        {edit && (
                            <FormGroup>
                                <AdaButton
                                    className="c-btn c-danger w-100"
                                    onClick={cancelEdit}
                                >
                                    <IntlMessages id="action.common.cancel"/>
                                </AdaButton>
                            </FormGroup>
                        )}
                    </Col>
                    <Col>
                        {edit && (
                            <FormGroup>
                                <AdaButton
                                    className="c-btn c-warning w-100"
                                    type="submit"
                                    onClick={() => setIsValid(true)}
                                    style={{marginLeft: 10}}
                                >
                                    <IntlMessages id="action.common.submit"/>
                                </AdaButton>
                            </FormGroup>
                        )}
                    </Col>

                </Row>
            </form>

            <div className="row ma-0 pt-3">
                <div className="col-sm-12 ">
                    <div className="roe-card-style">
                        <Row>
                            <Col className="col-sm-12">
                                {order.directClient !== undefined &&
                                order.directClient !== null && (
                                    <AssignmentDisplayClient
                                        clientObject={order.directClient}
                                        type={<IntlMessages id="assignment.client.direct"/>}
                                        termsOfPayment={order.termsOfPayment}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {order.subContractingClient !== undefined &&
            order.subContractingClient !== null && (<div className="row ma-0 pt-3">
                <div className="col-sm-12 ">
                    <div className="roe-card-style">
                        <Row>
                            <Col className="col-sm-12">
                                {order.subContractingClient !== undefined &&
                                order.subContractingClient !== null && (
                                    <AssignmentDisplayClient
                                        clientObject={order.subContractingClient}
                                        type={
                                            <IntlMessages id="assignment.client.undirect"/>
                                        }

                                        termsOfPayment={order.termsOfPayment}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>)}



            <div className="row ma-0">
                <div className="col-sm-12 ">
                    <Row className="pt-3">
                        <Col>
                            <div>
                                <span className="hash"># </span>
                                <IntlMessages id="assignment.price.and.sell"/>
                            </div>
                        </Col>
                    </Row>
                    <div className="roe-card-style  col-sm-12  mb-3 mt-1">
                        <Row className="ptb-10">
                            <Col className="col-sm-6">
                                <AssignmentSellPriceTable
                                    assignment={order} related={"order"}
                                    handlerSave={handlerSave}
                                    setValue={setValue}
                                    errors={errors}
                                    edit={edit}
                                    register={register}
                                />
                            </Col>
                            <Col className="col-sm-6">
                                <AssignmentDiscountPriceTable
                                    assignment={order} related={"order"}
                                    handlerSave={handlerSave}
                                    setValue={setValue}
                                    errors={errors}
                                    edit={edit}
                                    register={register}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="row ma-0 pt-3">
                <div className="col-sm-12 ">
                    <div className="roe-card-style">
                        <Row>
                            <Col className="col-sm-12">
                                <Documents
                                    setObjectResource={setOrder}
                                    objectResource={order}
                                    context={context} reload={false}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}
