import React, {useEffect, useState} from "react";
import axios from "axios";
import {Col, FormGroup, Row} from "reactstrap";
import IntlMessages from "../../../helpers/intlMessages";
import {useHistory} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import AssignmentDisplayCollab from "../components/assignment.consultation/assignment.display.collab";
import AssignmentDisplayClient from "../components/assignment.consultation/assignment.display.client";
import AssignmentContractTypeOrder from "../components/assignment.order/assignment.contractTypeOrder";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import AssignmentContractTypeAndDateOrder
    from "../components/assignment.consultation/assignment.display.contractTypeOrder";
import AssignementClient from "../components/assignment.client/assignement.client";
import Steps from "../components/assignment.consultation/steps";
import AssignmentAction from "../components/assignment.consultation/assignment.actions";
import AssignmentSellPriceTable from "../components/assignment.order/assignment.sellPriceTable";
import AssignmentDiscountPriceTable from "../components/assignment.order/assignment.discountPriceTable";
import Documents from "../components/documents";
import Events from "../components/events";
import * as service from "../../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName} from "../../../service/host";

import PageviewsChartWrapper from "../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";

const context = "assignments";
export default function AssignementEditForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();

    const [edit, setEdit] = useState(false);
    const [reload, setReload] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [assignment, setAssignment] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    let history = useHistory();

    const assignmentId = initAssignmentId();

    function initAssignmentId() {
        const {
            match: {params}
        } = props;
        return params.assignmentId;
    }

    const cancelEdit = () => {
        setEdit(false);
        service.getById(
            getHostByAppName(ASSIGNMENT_NAME),
            context,
            setAssignment,
            setErrorMessage,
            assignmentId
        );
    };

    const handlerSave = data => {
        console.log("in save data patch", {...data});
        service.put(
            getHostByAppName(ASSIGNMENT_NAME),
            `${context}/${assignment.id}`,
            callbackRes,
            callbackError,
            {...data}
        );
    };

    const callbackRes = res => {
        console.log("res",res.data);
        setAssignment(res.data);
        setEdit(false);
    };

    const callbackError = error => {
        console.log("error",error)
        setErrorMessage(error.response.data.message);
    };

    const onSubmitAssignment = data => {
        console.log("in submit", data);
        handlerSave({...assignment,
            ...data,
            beginAt: data.beginAt !== undefined ? data.beginAt.replace("T", " ") : null,
            endAt: data.endAt !== undefined ? data.endAt.replace("T", " ") : null
        });
    };

    useEffect(
        () => {
            if (assignmentId !== undefined) {
                service.getById(
                    getHostByAppName(ASSIGNMENT_NAME),
                    context,
                    setAssignment,
                    setErrorMessage,
                    assignmentId
                );
            }
        },
        [assignmentId]
    );

    return (
        <div>
            <Row className="col-sm-12">

                <Col className="col-sm-3">
                    <h4 className="mb-0 fs-22 bold-text" style={{color:"#FF0B0E"}}><IntlMessages id="assignment.title"/></h4>
                </Col>

                <Col className="col-sm-7">
                    <Row>
                        <Col>
                            <Steps
                                steps={assignment.status !== undefined ? assignment.status : []}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col className="col-sm-2">
                </Col>
                <Col className="col-sm-6">
                    {!edit && (
                        <AssignmentAction
                            context={context}
                            setEdit={setEdit} reload={cancelEdit}
                            setErrorMessage={setErrorMessage}
                            assignment={assignment}
                            setAssignment={setAssignment}
                        />
                    )}
                </Col>
            </Row>

            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="row ma-0 pt-3">
                        <div className="col-sm-12 ">
                            <div className="roe-card-style  col-sm-12">
                                <form onSubmit={handleSubmit(onSubmitAssignment)}>
                                    <Row className="ptb-10">
                                        <Col className="col-sm-6">
                                            <div className="display-5 c-text-danger">
                                                {errorMessage}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="ptb-10">
                                        <Col className="col-sm-6">
                                            <AssignmentDisplayCollab assignment={assignment}/>
                                        </Col>

                                        <Col className="col-sm-6">
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-3">
                                            <Row className="pt-3">
                                                <Col>
                                                    <div>
                                                        <span className="hash"># </span>
                                                        <IntlMessages id="employee.mission.Type"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3">
                                                <Col>
                                                    <AssignmentContractTypeAndDateOrder
                                                        assignment={assignment}
                                                        register={register}
                                                        edit={edit}
                                                        errors={errors}
                                                        setValue={setValue}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="pt-3">
                                                <Col>
                                                    <div>
                                                        <span className="hash"># </span>
                                                        <IntlMessages id="common.mission.description"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3 mr-3">
                                                <Col>
                                                    <div className="pl-3">
                                                        <AdaInputText
                                                            name="designation"
                                                            errors={errors}
                                                            innerRef={register}
                                                            defaultValue={
                                                                assignment !== undefined
                                                                    ? assignment.designation
                                                                    : ""
                                                            }
                                                            disabled={!edit}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                                    <Row className="pt-3">
                                                        <Col>
                                                            <div>
                                                                <span className="hash"># </span>
                                                                <IntlMessages id="assignment.mission.task"/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="pt-3 mr-3">
                                                        <Col>
                                                            <div className="pl-3">
                                                                <AdaInputText
                                                                    name="task"
                                                                    errors={errors}
                                                                    innerRef={register}
                                                                    defaultValue={
                                                                        assignment !== undefined
                                                                            ? assignment.task
                                                                            : ""
                                                                    }
                                                                    disabled={!edit}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="pt-3 mr-3">
                                                        <Col className="col-sm-7"/>
                                                        <Col>
                                                            {edit && (
                                                                <FormGroup>
                                                                    <AdaButton className="c-btn c-danger w-100" onClick={() => cancelEdit()}>
                                                                        <IntlMessages id="action.common.cancel"/>
                                                                    </AdaButton>

                                                                </FormGroup>
                                                            )}
                                                        </Col>
                                                        <Col>
                                                            {edit && (
                                                                <FormGroup>
                                                                    <AdaButton
                                                                        type="submit" className="c-btn c-warning w-100"
                                                                        onClick={() => setIsValid(true)}
                                                                        style={{marginLeft: 10}}
                                                                    >
                                                                        <IntlMessages id="action.common.submit"/>
                                                                    </AdaButton>
                                                                </FormGroup>
                                                            )}
                                                        </Col>
                                                    </Row>



                                                </div>
                                            </PageviewsChartWrapper>

                                        </Col>
                                    </Row>
                                </form>

                                <Row className="pt-3">
                                    <Col>
                                        <div>
                                            <span className="hash"># </span>
                                            <IntlMessages id="assignment.price.and.sell"/>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="roe-card-style  col-sm-12  mb-3 mt-1">
                                    <Row className="ptb-10">
                                        <Col className="col-sm-6">
                                            <AssignmentSellPriceTable
                                                assignment={assignment}
                                                handlerSave={handlerSave}
                                                setValue={setValue}
                                                errors={errors}
                                                edit={edit}
                                                register={register}
                                            />
                                        </Col>
                                        <Col className="col-sm-6">
                                            <AssignmentDiscountPriceTable
                                                assignment={assignment}
                                                handlerSave={handlerSave}
                                                setValue={setValue}
                                                errors={errors}
                                                edit={edit}
                                                register={register}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row ma-0 pt-3">
                        <div className="col-sm-12 ">
                            <div className="roe-card-style">
                                <Row>
                                    <Col className="col-sm-12">
                                        {assignment.directClient !== undefined &&
                                        assignment.directClient !== null && (
                                            <AssignmentDisplayClient
                                                clientObject={assignment.directClient}
                                                type={<IntlMessages id="assignment.client.direct"/>}
                                                termsOfPayment={assignment.termsOfPayment}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="row ma-0 pt-3">
                        <div className="col-sm-12 ">
                            <div className="roe-card-style">
                                <Row>
                                    <Col className="col-sm-12">
                                        {assignment.subContractingClient !== undefined &&
                                        assignment.subContractingClient !== null && (
                                            <AssignmentDisplayClient
                                                clientObject={assignment.subContractingClient}
                                                type={
                                                    <IntlMessages id="assignment.client.undirect"/>
                                                }
                                                termsOfPayment={assignment.termsOfPayment}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="row ma-0 pt-3">
                        <div className="col-sm-12 ">
                            <div className="roe-card-style">
                                <Row>
                                    <Col className="col-sm-12">
                                        <Documents
                                            setObjectResource={setAssignment}
                                            objectResource={assignment}
                                            context={context} reload={reload}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="row ma-0 pt-3">
                        <div className="col-sm-12 ">
                            <div className="roe-card-style">
                                <Row>
                                    <Col className="col-sm-12">
                                        <Events objectResource={assignment}/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    );
}
