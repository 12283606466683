import React, { useEffect, useState } from "react";
import { FormGroup, Row } from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import { AdaButton, AdaInputText, AdaSelect } from "@adaming/ada-react-component";
import * as specificService from "../../../service/employee.service";
import {GetOrganizationIdFromSession} from "../../../service/session.service";

import {
  ASSIGNMENT_NAME,
  CRM_NAME,
  EMPLOYEE_NAME,
  getHostByAppName,
  ORGANIZATION_NAME
} from "../../../service/host";
import { Controller } from "react-hook-form";
import * as service from "../../../service/crud.service";
import { AdaBankDetail } from "@adaming/ada-react-business-component";

export default function OrderFacturationDetails({
  register,
  setValue,
  errors,
  order,
  edit,
  client,
  control,
  dispatch
}) {
  const termsOfPaymentList = [{key : 0, value : 0,  label : "00 jours"},{key : 30, value : 30,  label : "30 jours"}, {key : 45, value : 45,  label : "45 jours"},{key : 60, value : 60,  label : "60 jours"}];
  const [bankDetails, setBankDetails] = useState([]);
  const [bankDetailsList, setBankDetailsList] = useState([]);
  const [errorMessage , setErrorMessage] = useState();
  const [termsOfPayment, setTermsOfPayment] = useState();
  const [rate, setRate] = useState(20.00);
  const [externalReference, setExternalReference] = useState([]);

  const [billingObj, setBillingObj] = useState({
    key: "",
    label: "",
    value: ""
  });
  const [messageFact, setMessageFact] = useState(null);

  useEffect(() => {
      register({name: "bankDetails"});
      register({name: "termsOfPayment"});
      register({name: "rate"});
      register({name: "externalReference"});

      console.log("ordersssss", order);
      if(order){
        setExternalReference(order.externalReference);
        setRate(order.rate);
        setTermsOfPayment(termsOfPaymentList.find((elem)=>elem.key==order.termsOfPayment));
      }

      service.getById(
        getHostByAppName(ORGANIZATION_NAME),
        "organizations",
        callBackInitListBankDetails,
        setErrorMessage,
        GetOrganizationIdFromSession()
    );
    
  }, [order]);


  useEffect(() => {
    if(!termsOfPayment && client){
      console.log("client", client);
      setTermsOfPayment(client.termsOfPayment);
    }
}, [client]);

useEffect(() => {

  if(order && order.bankDetails){
    console.log("set bank details value",order.bankDetails.designation) ;
    setBankDetails( bankDetailsList.find((elem) => elem.key === order.bankDetails.designation));
  }

}, [order, bankDetailsList]);




  const callBackInitListBankDetails = data =>{
    
    console.log("orga", data);
    let listOfBankDetails = data.bankDetails.map(elem => {return {key : elem.designation, label : elem.designation, value : elem}});
    setBankDetailsList(data.bankDetails.map(elem => {return {key : elem.designation, label : elem.designation, value : elem}}));
    console.log("listOfBankDetails",listOfBankDetails);
    if(order && order.bankDetails){
      setBankDetails( bankDetailsList.find((elem) => elem.key === order.bankDetails.designation));
    }

  }

  const handleChangeTermsOfPayment = selectedOption => {

    console.log("terms of Payment selected", selectedOption);
    if (selectedOption !== null) {
        setTermsOfPayment(selectedOption)
        setValue(`termsOfPayment`, selectedOption.key);
    } else {
        setValue(`termsOfPayment`, null);
    }
}

const handleChangeBankDetails = selectedOption => {

  if (selectedOption !== null) {
      setBankDetails(selectedOption)
      setValue(`bankDetails`, selectedOption.value);
  } else {
      setValue(`bankDetails`, null);
  }
}
  
 


  const handleChangeExternalReferenceValue = data => {
    console.log("external reference", data.target.value);
    setExternalReference(data.target.value);
    setValue(`externalReference`, data.target.value);
  };

  const handleChangeRateValue = data => {
    console.log("rate", data.target.value);
    setRate(data.target.value);
    setValue(`rate`, data.target.value);

  };


  
  return (
    <div className="roe-card-body col-sm-12">
      
      <Row>
        <Col className="col-sm-4">
          <IntlMessages id="assignment.termsOfPayment" />
          <FormGroup>
                        <AdaSelect
                            value={termsOfPayment}
                            isClearable
                            name="termsOfPayment"
                            errors={errors}
                            options={termsOfPaymentList}
                            onChange={handleChangeTermsOfPayment}
                            innerRef={register}
                            isDisabled={!edit}                        />
                    </FormGroup>

        </Col>

        <Col className="col-sm-4">
          <IntlMessages id="assignment.bankDetails" />
          <FormGroup>
                        <AdaSelect
                            value={bankDetails}
                            isClearable
                            name="bankDetails"
                            errors={errors}
                            options={bankDetailsList}
                            onChange={handleChangeBankDetails}
                            innerRef={register}
                            isDisabled={!edit}
                        />
                    </FormGroup>
        </Col>
      </Row>
      
      <Row>
      <Col className="col-sm-4">
        <IntlMessages id="assignment.rate" />
        <FormGroup>
        <AdaInputText
                  type="number"
                  placeholder="0.00 %"
                  step="0.01"
                  name="rate"
                  style={{height:38}}
                  errors={errors}
                  onChange={handleChangeRateValue}
                  value={rate}
                  innerRef={register}
                  disabled={!edit}
              />
        </FormGroup>

      </Col>

      <Col className="col-sm-4">
        
          <IntlMessages id="assignment.refexterne" />
        <FormGroup>
          <AdaInputText
                name="externalReference" style={{height:38}}
                errors={errors}
                onChange={handleChangeExternalReferenceValue}
                value={externalReference}
                innerRef={register}
                setValue={setValue}
                disabled={!edit}
              />
        </FormGroup>

          
      </Col>
    </Row>
   
      <br />

      

    </div>
  );
}
